import gql from "graphql-tag";
import apolloClient from "@/apolloClient.js";

export const createPhotographyCalendarTerm = async (email, date) =>
  await apolloClient.mutate({
    mutation: gql`
      mutation{
        createPhotographyCalendar(input: {
          data: {
            Email: "${email}"
            Date: "${date}"
          }
        }) 
        {photographyCalendar {id}}
      }
  `,
  });
