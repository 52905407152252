<template>
  <div class="w-1/6 mx-auto text-center font-bold select-none mt-12 min-w-max">
    Vyberte volné dny pro focení
    <div class="py-4">
      <vc-calendar
        is-expanded
        :attributes="attributes"
        @dayclick="onDayClick"
      />
    </div>
    <div
      v-if="days.length > 0"
      class="overscroll-y-contain overflow-auto h-24 rounded-md border-2 border-blue-700 text-body-1"
      :class="days.length > 2 ? 'pl-4' : ''"
    >
      <div
        class="flex justify-center py-1"
        v-for="(date, id) in dates"
        :key="id"
      >
        {{ date | moment("DD. MM. YYYY")
        }}<button
          class="focus:outline-none ml-2"
          @click="(idx) => days.splice(id, 1)"
        >
          <img src="../../../public/images/trash-2 1.png" alt="" />
        </button>
      </div>
    </div>
    <div v-if="days.length > 0">
      <button
        @click="save()"
        class="h-16 w-full px-8 mt-4 rounded-md bg-body-1 hover:opacity-80 duration-300 text-white focus:outline-none"
      >
        Přidat volné termíny
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createPhotographyCalendarTerm } from "@/graphql/mutations/photographyCalendarMutations";

export default {
  name: "PhotoTermDatePicker",
  data() {
    return {
      days: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  methods: {
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: this.$moment(day.date).format("YYYY-MM-DD"),
        });
      }
    },
    async save() {
      const freeDays = this.days.map((date) => date.date);
      if (freeDays.length > 0) {
        freeDays.forEach(async (day) => {
          await createPhotographyCalendarTerm(this.user.email, day);
        });
        return this.$toast(this.$t("SuccessMessages.SuccessfullySaved"), {
          className: ["et-info"],
        });
      } else
        return this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
    },
  },
};
</script>
